import React from "react";
import "./css/Footer.css";
import { TwitterIcon, InstagramIcon, WhatsappIcon } from "./Icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
	return (
		<div className="Footer">
			<div className="footer__container">
				<div className="footer__container__elements">
					<div
						style={{
							height: "30vh",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
						}}
					>
						<div className="footer__container__logo"></div>
						<div className="footer__container__socialMedia">
							<a
								href="https://twitter.com/find_bait"
								style={{
									backgroundColor: "#FFF",
									borderRadius: "5px",
									padding: "4px 4px",
									color: "#fff",
									fontSize: "16px",
									marginRight: "15px",
								}}
							>
								<TwitterIcon />
							</a>
							<a
								href="https://www.instagram.com/findbait/"
								style={{
									backgroundColor: "#FFF",
									borderRadius: "5px",
									padding: "4px 4px",
									color: "#fff",
									fontSize: "16px",
									marginRight: "15px",
								}}
							>
								<InstagramIcon />
							</a>
							<a
								href="https://api.whatsapp.com/send?phone=96599225309"
								style={{
									backgroundColor: "#FFF",
									borderRadius: "5px",
									padding: "4px 4px",
									color: "#fff",
									fontSize: "16px",
								}}
							>
								<WhatsappIcon />
							</a>
						</div>
					</div>
					<div className="footer__container__links">
						<a href="/about">About Us</a>
						<a href="/contactus">Contact</a>
						<a href="/terms">Terms and Conditions</a>
						<a href="/privacy">Privacy Policy</a>
					</div>
					<div className="footer__container__buttonRights">
						<p>© Bait. All Rights Reserved</p>
						<br />
						<p>
							Made with{" "}
							<FontAwesomeIcon icon="fa-solid fa-heart" color="#FFF" /> by{" "}
							<a className="SecureHoot" href="https://securehoot.com">
								SecureHoot
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
