import React from "react";
import "./css/Article.css";

const Article = (props) => {
  return (
    <a className="Article" href={`./properties/${props.coll}/${props.id}`}>
      <img className="article__cover" src={props.cover} alt={props.title} />
      <div className="article__data">
          <h4 className="article__data__title">{props.title}</h4>
        <p className="article__data__excerpt">{props.excerpt}</p>
        <h4 className="article__data__title">{props.price}</h4>
      </div>
    </a>
  );
};

export default Article;
