import React, { useEffect, useState } from "react";
import "./css/Articles.css";
import Article from "./Article";
import { databases } from "../appwrite/services";

const Articles = () => {
	const [data, setData] = useState([]);
	const [search, setSearch] = useState("");
	const [filteredData, setFilteredData] = useState([]);
	const [visibleCount, setVisibleCount] = useState(10);

	useEffect(() => {
		async function fetchRent() {
			const response = await databases.listDocuments("Properties", "Rent");
			return response.documents;
		}

		async function fetchSale() {
			const response = await databases.listDocuments("Properties", "Buy");
			return response.documents;
		}

		Promise.all([fetchRent(), fetchSale()]).then((values) => {
			setData([...values[0], ...values[1]]);
			setFilteredData([...values[0], ...values[1]]);
		});
	}, []);

	const handleSearch = (event) => {
		const searchQuery = event.target.value;
		setSearch(searchQuery);

		const filteredArticles = data.filter((article) => {
			return (
				article.proprtyName.includes(searchQuery) ||
				`${article.code}`.includes(searchQuery)
			);
		});
		setFilteredData(filteredArticles);
	};

	const loadMore = () => {
		setVisibleCount((prevCount) => prevCount + 10);
	};

	return (
		<section className="Articles" id="properties">
			<div className="Articles__container">
				<h2 className="Articles__title">العقارات</h2>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<input
						type="text"
						value={search}
						onChange={handleSearch}
						placeholder="Search"
						style={{
							width: "100%",
							maxWidth: "400px",
							margin: "0 auto",
							textAlign: "center",
							height: "45px",
							border: "1px solid #ccc",
							outline: "none",
							backgroundColor: "#fff",
							padding: "10px 15px",
							borderRadius: "25px",
							fontSize: "16px",
							boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
							marginBottom: "30px",
							transition: "all 0.3s ease",
						}}
						onFocus={(e) =>
							(e.target.style.boxShadow = "0 4px 12px rgba(0, 0, 0, 0.2)")
						}
						onBlur={(e) =>
							(e.target.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)")
						}
					/>
				</div>
				<div className="Articles__article">
					{filteredData.slice(0, visibleCount).map((property, index) => (
						<Article
							coll={property.$collectionId}
							key={index}
							cover={property.proprtyImg}
							id={property.$id}
							title={property.proprtyName}
							excerpt={property.description}
							price={
								property.$collectionId === "Rent"
									? `${property.Price} KWD/M`
									: `${property.Price} KWD`
							}
						/>
					))}
				</div>
				{visibleCount < filteredData.length && (
					<button
						onClick={loadMore}
						style={{
							display: "block",
							margin: "20px auto",
							padding: "10px 20px",
							fontSize: "16px",
							cursor: "pointer",
							backgroundColor: "#F8545F",
							color: "#fff",
							border: "none",
							borderRadius: "5px",
						}}
					>
						اظهار المزيد
					</button>
				)}
			</div>
		</section>
	);
};

export default Articles;
