import React from "react";
import "./css/Navbar.css";
import logo from "../images/logo.png";
import hamburgerMenuico from "../images/icon-hamburger.svg";
import closeMenuico from "../images/icon-close.svg";
import Button from "./Button";

const Navbar = (props) => {
  return (
    <div className="Navbar">
      <div className="navbar__container">
        <div className="navbar__container__logo">
          <img src={logo} alt="logo" style={{objectFit: "contain" , width: 100}} />
        </div>
        <div className="navbar__container__pages">
          <a href="/#properties">العقارات</a>
          <a href="/contactus">تواصل معنا</a>
          <a href="/#home">الرئيسية</a>
        </div>
        <div className="navbar__container__button">
          <Button link="./#RequestInvite" cont="Download" />
        </div>
        <div className="navbar__container__hamburgerMenu">
          {!props.show ? (
            <img
              onClick={() => props.openModal()}
              src={hamburgerMenuico}
              alt="Menu"
            />
          ) : (
            <img
              onClick={() => props.closeModal()}
              src={closeMenuico}
              alt="Menu"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
